<template>
  <div class="form--group form--group-switch done--estimation-switch">
    <div class="left--col">
      <span>{{ $t('addListing.doneEstimation') }}</span>
    </div>
    <div class="right--col">
      <div class="d-flex" :class="{ 'flex-wrap': $mq === 'xs' }">
        <button type="button" class="btn px-4" :class="{ selected: isDone }" @click="setDone(true)">
          {{ $t('addListing.doneTrue') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: !isDone }"
          @click="setDone(false)"
        >
          <span style="position: relative;">
            {{ $t('addListing.doneFalse') }}
          </span>
        </button>
      </div>
      <div v-show="!isDone" class="multiselect--wrapper">
        <div class="multiselect--label">{{ $t('general.year') }}</div>
        <multiselect
          name="rentType"
          v-model="doneYearEst"
          :options="yearOptions"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="false"
          style="width: 150px;"
        ></multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
export default {
  name: 'done-estimation-section',
  components: { Multiselect },
  data() {
    return {
      yearOptions: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    };
  },
  computed: {
    isDone: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.isDone;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_IS_DONE', val);
      },
    },
    doneYearEst: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.doneYearEst;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DONE_YEAR_EST', val);
      },
    },
  },
  methods: {
    falseClickHandler() {
      this.doneYearEst = 2020;
    },
    setDone(doneStatus) {
      if (!doneStatus) this.falseClickHandler();
      this.isDone = doneStatus;
    },
  },
};
</script>

<style scoped></style>
